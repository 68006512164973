/**
 * @constant {String}
 */
export const FIO_REQID_HEADER = 'x-fio-reqid';

/**
 * @constant {String}
 */
export const OTA_LITE_SERVICE_HEADER = 'ota-lite-svc';

/**
 * @constant {String}
 */
export const CONTENT_TYPE_HEADER = 'Content-Type';

/**
 * @constant {String}
 */
export const DEFAULT_CONTENT_TYPE = 'application/json; charset=UTF-8';

/**
 * @constant {String}
 */
export const CONSENT_COOKIE_NAME = 'fio_consent';

/**
 * @constant {String}
 * @default
 */
export const DEFAULT_TIME_ZONE = 'UTC';

/**
 * @constant {String}
 * @default
 */
export const DEFAULT_LOCALE = 'en';

/**
 * @constant {String}
 * @default
 */
export const GITHUB_LOGIN_PROVIDER = 'github';

/**
 * @constant {String}
 * @default
 */
export const GOOGLE_LOGIN_PROVIDER = 'google';

/**
 * @constant {Object}
 */
export const NORMALIZE_EMAIL_OPTIONS = {
  all_lowercase: true,
  gmail_remove_dots: false,
  gmail_remove_subaddress: false,
  gmail_convert_googlemaildotcom: false,
  outlookdotcom_lowercase: false,
  outlookdotcom_remove_subaddress: false,
  yahoo_lowercase: false,
  yahoo_remove_subaddress: false,
  icloud_lowercase: false,
  icloud_remove_subaddress: false,
};

/**
 * @constant {Number}
 */
export const MIN_PASSWORD_LENGTH = 10;

/**
 * @constant {Number}
 */
export const MAX_PASSWORD_LENGTH = 1024;
